export const pageUrlGenerator = (page) =>
  `${window.location.protocol}//${window.location.host}${page}`;

export const KingmakerGames = {
  kingmakerGames: {
    slide1: {
      CardPoker5: {
        redirectUrl: "/casino/km-5-card-poker",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/5-card-poker.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Card Poker",
        providerName: "Kingmaker",
      },
      up7down7: {
        redirectUrl: "/casino/km-7-up-7-down",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/7-up-7-down.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "7 up 7 down",
        providerName: "Kingmaker",
      },
      cards32: {
        redirectUrl: "/casino/km-32-cards",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/32-cards.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "32 Cards",
        providerName: "Kingmaker",
      },
      AndarBahar: {
        redirectUrl: "/casino/km-andar-bahar",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/andar-bahar.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Andar Bahar",
        providerName: "Kingmaker",
      },
      Baccarat: {
        redirectUrl: "/casino/km-baccarat",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/baccarat.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Baccarat",
        providerName: "Kingmaker",
      },
      BaiBuu: {
        redirectUrl: "/casino/km-bai-buu",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/bai-buu.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Bai Buu",
        providerName: "Kingmaker",
      },
      BaiCao: {
        redirectUrl: "/casino/km-bai-cao",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/bai-cao.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Bai Cao",
        providerName: "Kingmaker",
      },
      Belangkai2: {
        redirectUrl: "/casino/km-belangkai-2",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/belangkai-2.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Belangkai 2",
        providerName: "Kingmaker",
      },
      Blackjack: {
        redirectUrl: "/casino/km-blackjack",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/blackjack.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Blackjack",
        providerName: "Kingmaker",
      },
      BolaGolek: {
        redirectUrl: "/casino/km-bola-golek",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/bola-golek.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Bola Golek",
        providerName: "Kingmaker",
      },
      BolaTangkas: {
        redirectUrl: "/casino/km-bola-tangkas",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/bola-tangkas.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Bola Tangkas",
        providerName: "Kingmaker",
      },
      BonusDice: {
        redirectUrl: "/casino/km-bonus-dice",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/bonus-dice.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Bonus Dice",
        providerName: "Kingmaker",
      },
      CardHiLo: {
        redirectUrl: "/casino/km-card-hi-lo",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/card-hi-lo.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Card Hi Lo",
        providerName: "Kingmaker",
      },
      CashRocket: {
        redirectUrl: "/casino/km-cash-rocket",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/cash-rocket.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Cash Rocket",
        providerName: "Kingmaker",
      },
      CleopatrasTreasure: {
        redirectUrl: "/casino/km-cleopatras-treasure",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/cleopatras-treasure.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Cleopatras Treasure",
        providerName: "Kingmaker",
      },
      CockfightingArena: {
        redirectUrl: "/casino/km-cockfighting-arena",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/cockfighting-arena.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Cockfighting Arena",
        providerName: "Kingmaker",
      },
      CoinPusher: {
        redirectUrl: "/casino/km-coin-pusher",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/coin-pusher.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Coin Pusher",
        providerName: "Kingmaker",
      },
      CoinToss: {
        redirectUrl: "/casino/km-coin-toss",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/coin-toss.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Coin Toss",
        providerName: "Kingmaker",
      },
      ColorGame: {
        redirectUrl: "/casino/km-color-game",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/color-game.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Color Game",
        providerName: "Kingmaker",
      },
      DotaHiLo: {
        redirectUrl: "/casino/km-dota-hi-lo",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/dota-hi-lo.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Dota Hi Lo",
        providerName: "Kingmaker",
      },
      DragonTiger2: {
        redirectUrl: "/casino/km-dragon-tiger-2",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/dragon-tiger-2.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Dragon Tiger 2",
        providerName: "Kingmaker",
      },
      EliteAviatorClub: {
        redirectUrl: "/casino/km-elite-aviator-club",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/elite-aviator-club.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Elite Aviator Club",
        providerName: "Kingmaker",
      },
      EuropeanRoulette: {
        redirectUrl: "/casino/km-european-roulette",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/european-roulette.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "European Roulette",
        providerName: "Kingmaker",
      },
      FanTvanClassic: {
        redirectUrl: "/casino/km-fan-tvan-classic",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/fan-tvan-classic.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Fan Tan Classic",
        providerName: "Kingmaker",
      },
      FishPrawnCrab2: {
        redirectUrl: "/casino/km-fish-prawn-crab-2",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/fish-prawn-crab-2.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Fish Prawn Crab 2",
        providerName: "Kingmaker",
      },
      FruitRoulette: {
        redirectUrl: "/casino/km-fruit-roulette",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/fruit-roulette.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "FruitRoulette",
        providerName: "Kingmaker",
      },
      GemsOfValhalla: {
        redirectUrl: "/casino/km-gems-of-valhalla",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/gems-of-valhalla.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Gems Of Valhalla",
        providerName: "Kingmaker",
      },
      GoldMines: {
        redirectUrl: "/casino/km-gold-mines",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/gold-mines.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Gold Mines",
        providerName: "Kingmaker",
      },
      Heist: {
        redirectUrl: "/casino/km-heist",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/heist.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "heist",
        providerName: "Kingmaker",
      },
      InterstellarRun: {
        redirectUrl: "/casino/km-interstellar-run",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/interstellar-run.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "InterstellarRun",
        providerName: "Kingmaker",
      },
      JackpotJump: {
        redirectUrl: "/casino/km-jackpot-jump",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/jackpot-jump.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Jackpot Jump",
        providerName: "Kingmaker",
      },
      JhandiMunda: {
        redirectUrl: "/casino/km-jhandi-munda",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/jhandi-munda.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Jhandi Munda",
        providerName: "Kingmaker",
      },
      KMPokDeng: {
        redirectUrl: "/casino/km-km-pok-deng",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/km-pok-deng.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "KM Pok Deng",
        providerName: "Kingmaker",
      },
      KMPowerBall: {
        redirectUrl: "/casino/km-km-power-ball",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/km-power-ball.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "KM Power Ball",
        providerName: "Kingmaker",
      },
      KMVirtualHorseRacing: {
        redirectUrl: "/casino/km-km-virtual-horse-racing",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/km-virtual-horse-racing.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "KM Virtual Horse Racing",
        providerName: "Kingmaker",
      },
      Ludo: {
        redirectUrl: "/casino/km-ludo",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/ludo.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Ludo",
        providerName: "Kingmaker",
      },
      MarbleRacing: {
        redirectUrl: "/casino/km-marble-racing",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/marble-racing.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Marble Racing",
        providerName: "Kingmaker",
      },
      MaxKeno: {
        redirectUrl: "/casino/km-max-keno",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/max-keno.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Max Keno",
        providerName: "Kingmaker",
      },
      Minesweeper: {
        redirectUrl: "/casino/km-minesweeper",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/minesweeper.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Minesweeper",
        providerName: "Kingmaker",
      },
      MonkeyKingRoulette: {
        redirectUrl: "/casino/km-monkey-king-roulette",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/monkey-king-roulette.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Monkey King Roulette",
        providerName: "Kingmaker",
      },
      OlympusGlory: {
        redirectUrl: "/casino/km-olympus-glory",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/olympus-glory.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Olympus Glory",
        providerName: "Kingmaker",
      },
      PaiKang: {
        redirectUrl: "/casino/km-pai-kang",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/pai-kang.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Pai Kang",
        providerName: "Kingmaker",
      },
      PenguinPanic: {
        redirectUrl: "/casino/km-penguin-panic",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/penguin-panic.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Penguin Panic",
        providerName: "Kingmaker",
      },
      Plinko: {
        redirectUrl: "/casino/km-plinko",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/plinko.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Plinko",
        providerName: "Kingmaker",
      },
      PokerRoulette: {
        redirectUrl: "/casino/km-poker-roulette",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/poker-roulette.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Poker Roulette",
        providerName: "Kingmaker",
      },
      Pusoy: {
        redirectUrl: "/casino/km-pusoy",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/pusoy.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Pusoy",
        providerName: "Kingmaker",
      },
      RoosterBlitz: {
        redirectUrl: "/casino/km-rooster-blitz",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/rooster-blitz.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Rooster Blitz",
        providerName: "Kingmaker",
      },
      SicBo: {
        redirectUrl: "/casino/km-sicbo",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/sicbo.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Rooster Blitz",
        providerName: "Kingmaker",
      },
      SugarBlast: {
        redirectUrl: "/casino/km-sugar-blast",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/sugar-blast.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Sugar Blast",
        providerName: "Kingmaker",
      },
      SugarBlastFrenzy: {
        redirectUrl: "/casino/km-sugar-blast-frenzy",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/sugar-blast-frenzy.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Sugar Blast Frenzy",
        providerName: "Kingmaker",
      },
      TaiXiu: {
        redirectUrl: "/casino/km-tai-xiu",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/tai-xiu.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Tai Xiu",
        providerName: "Kingmaker",
      },
      TeenPatti: {
        redirectUrl: "/casino/km-teen-patti",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/teen-patti.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Teen Patti",
        providerName: "Kingmaker",
      },
      ThaiFishPrawnCrab: {
        redirectUrl: "/casino/km-thai-fish-prawn-crab",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/thai-fish-prawn-crab.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Thai Fish Prawn Crab",
        providerName: "Kingmaker",
      },
      ThaiHiLo2: {
        redirectUrl: "/casino/km-thai-hi-lo-2",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/thai-hi-lo-2.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Thai Hi Lo 2",
        providerName: "Kingmaker",
      },
      Tongits: {
        redirectUrl: "/casino/km-tongits",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/tongits.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Tongits",
        providerName: "Kingmaker",
      },
      TripleChance: {
        redirectUrl: "/casino/km-triple-chance",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/triple-chance.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Triple Chance",
        providerName: "Kingmaker",
      },
      VideoPoker: {
        redirectUrl: "/casino/km-video-poker",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/video-poker.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Video Poker",
        providerName: "Kingmaker",
      },
      VietFishPrawnCrab: {
        redirectUrl: "/casino/km-viet-fish-prawn-crab",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/viet-fish-prawn-crab.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Viet Fish Prawn Crab",
        providerName: "Kingmaker",
      },
      VirtualAnimalRace: {
        redirectUrl: "/casino/km-virtual-animal-race",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/virtual-animal-race.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Virtual Animal Race",
        providerName: "Kingmaker",
      },
      VirtualGreyhoundRacing: {
        redirectUrl: "/casino/km-virtual-greyhound-racing",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/virtual-greyhound-racing.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Virtual Greyhound Racing",
        providerName: "Kingmaker",
      },
      VirtualTreadmillRacing: {
        redirectUrl: "/casino/km-virtual-treadmill-racing",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/virtual-treadmill-racing.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Virtual Treadmill Racing",
        providerName: "Kingmaker",
      },
      XocLightning: {
        redirectUrl: "/casino/km-xoc-lightning",
        code: "",
        casino: "",
        imgUrl: "./images/kingmaker/xoc-lightning.jpg",
        homeUrl: pageUrlGenerator("/kingmaker"),
        name: "Xoc Lightning",
        providerName: "Kingmaker",
      },
    },
    name: "Kingmaker",
    key: "kingmaker",
  },
};
