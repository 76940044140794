import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import { KingmakerGames } from "../../../../lib/data/KingmakerGames.data";
import { BiSearch } from "react-icons/bi";
// import PlayIcon from "../../../../assets/images/icons/play.png";
import BackIcon from "../../../../assets/svg/BackIcon";

const Kingmaker = () => {
  const categoriesTab = [{ name: "Kingmaker", key: "kingmaker" }];
  const [activeCategory, setActiveCategory] = useState("kingmaker");
  const { isAuth } = useSelector((state) => state.auth);
  const [filteredData, setFilteredData] = useState([]);
  const [isSearched, setIsSearched] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  return (
    <>
      <HeaderAfterLogin />

      <main className="main mt-4">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h2 className="sectionTitle">Kingmaker</h2>
            <div className="back-link" onClick={() => navigate(-1)}>
              Back <BackIcon />
            </div>
          </div>
          <div className="casinoProvidersGames games-section">
            {!isSearched ? (
              <ul className="games">
                {Array.isArray(Object.values(KingmakerGames)) &&
                  Object.values(KingmakerGames)
                    .filter((f) => f?.key === activeCategory)
                    .map((slide) =>
                      Object.values(slide)
                        .filter((f) => typeof f !== "string")
                        .map((game) =>
                          Object.values(game).map((item, idx) => (
                            <li key={"casinogame" + idx}>
                              <a
                                className="play-button"
                                href={item.redirectUrl}
                              >
                                <img src={item.imgUrl} alt={item.name} />
                                <div className="play-btn">
                                  {/* <img src={PlayIcon} alt="PlayIcon" /> */}
                                </div>
                                <div className="name">{item.name}</div>
                              </a>
                            </li>
                          ))
                        )
                    )}
              </ul>
            ) : (
              <ul className="games">
                {filteredData.length > 0 &&
                  filteredData.map((game, idx) => (
                    <li key={"casinogame" + idx}>
                      <a className="play-button" href={game.redirectUrl}>
                        <img src={game.imgUrl} alt={game.name} />
                        <div className="play-btn">
                          {/* <img src={PlayIcon} alt="PlayIcon" /> */}
                        </div>
                        <div className="name">{game.name}</div>
                      </a>
                    </li>
                  ))}
              </ul>
            )}
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default Kingmaker;
